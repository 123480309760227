import React, { useContext } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';

import FieldText from '../field/formikText';

import { defaultValue } from '../../lib/defaults';
import { get } from '../../utils/objects';
import ClientInfoInterestsAPIContext, { ClientInfoInterestsAPIProvider } from '../../services/clientInfoInterests';
import ClientInfoInterestsUpdateAPIContext, { ClientInfoInterestsUpdateAPIProvider } from '../../services/clientInfoInterestsUpdate';
import Messages from '../../components/messages';
import MessagesContext from '../../context/messages';
import Card from '../card';
import Button from '../button';
import Icon from '../icon';
import LoadingIndicator from '../LoadingIndicator';
import { removeAccessToken } from '../../lib/oauth';
import ScrollToFieldError from '../ScrollToFieldError';

// Only send interests with titles that are set.
const normalizeRequest = values => values.interests.filter(interest => interest.title && interest.title.length > 0);

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);

      return ((touch && error)
        ? (<div className="field__error">{error}</div>)
        : null
      );
    }}
  </Field>
);

const InterestsInner = ({
  id,
  heading = 'Interests / Hobbies',
  onSuccess = () => {},
  onCancel
}) => {
  const api = useContext(ClientInfoInterestsAPIContext);
  const { isResolved, data } = api;
  const clientUpdateAPI = useContext(ClientInfoInterestsUpdateAPIContext);
  const { messages, setMessages } = useContext(MessagesContext);

  if (!isResolved) {
    return (<Card>
      <div className="card--inner">
        <LoadingIndicator />
      </div>
    </Card>);
  }

  const interestFields = {
    title: "",
    duration: ""
  };

  return (
    <div className="container-panel">
      <Card>
        <Formik
          initialValues={{
            interests: data
          }}
          enableReinitialize={true}
          validate={values => {}}
          onSubmit={(values, { setSubmitting }) => {
            clientUpdateAPI.load(id, normalizeRequest(values))
              .then(response => {

                /*
                 * Access token will be revoked by the server on
                 * a successful client update.
                 */
                removeAccessToken();

                setMessages([
                  {
                    type: 'status',
                    text: 'Hobbies & interests information saved.'
                  }
                ]);
                setSubmitting(false);
                onSuccess();
              })
              .catch(error => {
                let errors = [];

                console.log(error);
                if (error.response && error.response.errors) {
                  errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title));
                } else if (error.status) {
                  errors.push(error.status);
                }

                setMessages(errors.map(err => ({
                  type: 'error',
                  text: err
                })));
                setSubmitting(false);
              });
          }}
        >
          {({ values, errors, isValid, submitCount, isSubmitting }) => (
            <Form>
              <ScrollToFieldError submitCount={submitCount} isValid={isValid} errors={errors} />
              <div className="card--inner">
                <Messages messages={messages} />
                <h1>{heading}</h1>
              </div>
              <FieldArray name="interests" render={arrayHelpers => (
                <div>
                  {values.interests.map((interest, i) => (
                    <div
                      key={i}
                      className={`card--inner-tight layout--even-2col ${i % 2 === 0
                        ? 'card--row-alt'
                        : 'card--row'}`
                      }
                    >
                      <Field
                        type="text"
                        name={`interests[${i}].title`}
                      >
                        {({ field }) => (
                          <FieldText {...field} label="Interest / Hobby" />
                        )}
                      </Field>

                      <Field
                        type="text"
                        name={`interests[${i}].duration`}
                      >
                        {({ field }) => (
                          <FieldText {...field} label="How long have you done this?" placeholder="1-5 years" />
                        )}
                      </Field>
                    </div>
                  ))}
                  <div className="card--inner-tight">
                    <Button theme="secondary" type="button" onClick={() => arrayHelpers.push(Object.assign({}, interestFields))}>
                      <Icon icon="plus" /> Add Another Interest / Hobby
                    </Button>
                  </div>
                </div>
              )} />
              <div className="card--inner card--divider layout-add-submit">
                <div className="button-group">
                  <Button theme="primary" type="submit" disabled={isSubmitting}>
                    <span className="button__label">Continue</span>
                    <Icon icon="forward" theme="primary" />
                  </Button>
                  {onCancel && (
                    <Button theme="secondary" type="button" disabled={isSubmitting} onClick={onCancel}>
                      <span className="button__label" >Cancel</span>
                      <Icon icon="close" theme="secondary" />
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

const Interests = props => (
  <ClientInfoInterestsAPIProvider {...props}>
    <ClientInfoInterestsUpdateAPIProvider {...props}>
      <InterestsInner {...props} />
    </ClientInfoInterestsUpdateAPIProvider>
  </ClientInfoInterestsAPIProvider>
);

export default Interests;
