import React, { useContext } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';

import FieldTextArea from '../field/textarea';
import FieldRadios from '../field/radioGroup';
import FieldText from '../field/formikText';

import ClientUpdateAPIContext, { ClientUpdateAPIProvider } from '../../services/clientUpdate';
import ClientInfoFinalAPIContext, { ClientInfoFinalAPIProvider } from '../../services/clientInfoFinal';
import ClientInfoFinalUpdateAPIContext, { ClientInfoFinalUpdateAPIProvider } from '../../services/clientInfoFinalUpdate';
import Messages from '../../components/messages';
import MessagesContext from '../../context/messages';
import Card from '../card';
import Button from '../button';
import Icon from '../icon';
import LoadingIndicator from '../LoadingIndicator';

import { defaults } from '../../lib/defaults';
import { removeAccessToken } from '../../lib/oauth';

const FinalInner = ({
  id,
  heading = 'Final',
  onSuccess = () => {},
  onCancel
}) => {
  const api = useContext(ClientInfoFinalAPIContext);
  const { isResolved, data } = api;
  const clientFinalUpdateAPI = useContext(ClientInfoFinalUpdateAPIContext);
  const clientUpdateAPI = useContext(ClientUpdateAPIContext);
  const { messages, setMessages } = useContext(MessagesContext);

  if (!isResolved) {
    return (<Card>
      <div className="card--inner">
        <LoadingIndicator />
      </div>
    </Card>);
  }

  const client = api.data;

  return (
    <div className="container-panel">
      <Card>
        <Formik
          initialValues={{
            referred: data.referred,
            referredAd: data.referredAd,
            referredOther: data.referredOther,
            referredDetails: data.referredDetails
          }}
          enableReinitialize={true}
          validate={values => {}}
          onSubmit={(values, { setSubmitting }) => {
            clientFinalUpdateAPI.load(id, values)
              .then(response =>

                /*
                 * Access token will be revoked by the server on
                 * a successful client update.
                 */
                removeAccessToken())
              .then(() =>
                // Mark info as complete.
                clientUpdateAPI.load(id, {
                  infoComplete: 1
                }))
              .then(response => {

                /*
                 * Access token will be revoked by the server on
                 * a successful client update.
                 */
                removeAccessToken();

                setMessages([
                  {
                    type: 'status',
                    text: 'Feedback information saved.'
                  }
                ]);
                setSubmitting(false);
                onSuccess();
              })
              .catch(error => {
                let errors = [];

                console.log(error);
                if (error.response && error.response.errors) {
                  errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title));
                } else if (error.status) {
                  errors.push(error.status);
                }

                setMessages(errors.map(err => ({
                  type: 'error',
                  text: err
                })));
                setSubmitting(false);
              });
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange, isSubmitting }) => (
            <Form>
              <div className="card--inner">
                <Messages messages={messages} />
                <h1>One last Question...</h1>
              </div>
              <div className="card--inner-tight">
                <FieldRadios
                  id="referred"
                  value={values.referred}
                  label="How Did you hear about JOCRF?"
                  options={defaults.referred.map(referred => ({
                    label: referred.label,
                    value: referred.value
                  }))}
                  onChange={handleChange}
                />
              </div>

              {/* Only show is "ad" chosen */}
              {values.referred == "ad" &&
                <div className="card--inner-tight">
                  <Field
                    type="text"
                    name="referredAd"
                  >
                    {({ field }) => (
                      <FieldText {...field} label="Please specify where you saw an ad" />
                    )}
                  </Field>
                </div>
              }

              {/* Only show is "other" chosen */}
              {values.referred == "other" &&
                <div className="card--inner-tight">
                  <Field
                    type="text"
                    name="referredOther"
                  >
                    {({ field }) => (
                      <FieldText {...field} label="Please specify where else" />
                    )}
                  </Field>
                </div>
              }


              <div className="card--inner-tight">
                <FieldTextArea
                  id="referredDetails"
                  label="Additional Details"
                  value={values.referredDetails}
                  onChange={handleChange}
                />
              </div>
              <div className="card--inner card--divider layout-add-submit">
                <div className="button-group">
                  <Button theme="primary" type="submit" disabled={isSubmitting}>
                    <span className="button__label">Complete</span>
                    <Icon icon="forward" theme="primary" />
                  </Button>
                  {onCancel && (
                    <Button theme="secondary" type="button" disabled={isSubmitting} onClick={onCancel}>
                      <span className="button__label" >Cancel</span>
                      <Icon icon="close" theme="secondary" />
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

const Final = props => (
  <ClientInfoFinalAPIProvider {...props}>
    <ClientInfoFinalUpdateAPIProvider {...props}>
      <ClientUpdateAPIProvider {...props}>
        <FinalInner {...props} />
      </ClientUpdateAPIProvider>
    </ClientInfoFinalUpdateAPIProvider>
  </ClientInfoFinalAPIProvider>
);

export default Final;
