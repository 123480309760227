// Return value or empty string if undefined
export const defaultValue = (value, def) => value || def || ''

// Store the default duration of various apointments.
export const defaultDuration = number => ({ 1: 3.5, 2: 3.5, 3: 1.5, 4: 1.5 }[number])

export const defaultGrades = [
  {
    label: 'Junior High',
    options: [
      {
        value: 'junior-6',
        label: '6'
      },
      {
        value: 'junior-7',
        label: '7'
      },
      {
        value: 'junior-8',
        label: '8'
      }
    ]
  },
  {
    label: 'High School',
    options: [
      {
        value: 'high-9',
        label: '9'
      },
      {
        value: 'high-10',
        label: '10'
      },
      {
        value: 'high-11',
        label: '11'
      },
      {
        value: 'high-12',
        label: '12'
      }
    ]
  },
  {
    label: 'College',
    options: [
      {
        value: 'college-1',
        label: '1'
      },
      {
        value: 'college-2',
        label: '2'
      },
      {
        value: 'college-3',
        label: '3'
      },
      {
        value: 'college-4',
        label: '4'
      },
      {
        value: 'college-5',
        label: '5'
      }
    ]
  },
  {
    label: 'Graduate School',
    options: [
      {
        value: 'grad-1',
        label: '1'
      },
      {
        value: 'grad-2',
        label: '2'
      },
      {
        value: 'grad-3',
        label: '3'
      },
      {
        value: 'grad-4',
        label: '4'
      },
      {
        value: 'grad-5',
        label: '5'
      }
    ]
  }
]

export const defaultEducation = [
  {
    value: 'fewer-12',
    label: 'Fewer than 12'
  },
  {
    value: 'high-school',
    label: 'High School Graduate'
  },
  {
    value: 'college-some',
    label: 'Some College'
  },
  {
    value: 'college',
    label: 'College Degree'
  },
  {
    value: 'advanced',
    label: 'Advanced Degree'
  }
]

export const defaults = {
  preferred: [
    {
      value: 'email',
      label: 'Email'
    },
    {
      value: 'phone',
      label: 'Phone'
    }
  ],
  sex: [
    {
      value: 'Male',
      label: 'Male'
    },
    {
      value: 'Female',
      label: 'Female'
    }
  ],
  confirmation: [
    {
      value: 'yes',
      label: 'Yes'
    },
    {
      value: 'no',
      label: 'No'
    }
  ],
  confirmationValue: [
    {
      value: '1',
      label: 'Yes'
    },
    {
      value: '0',
      label: 'No'
    }
  ],
  twinOrTriplet: [
    {
      value: 'No',
      label: 'No'
    },
    {
      value: 'Twin',
      label: 'Twin',
    },
    {
      value: 'Triplet',
      label: 'Triplet'
    }
  ],
  referred: [
    {
      value: 'familyMemberTested',
      label: 'Family member tested'
    },
    {
      value: 'friendMemberTested',
      label: 'Friend tested'
    },
    {
      value: 'friendfamilyNotTested',
      label: 'Family member / Friend NOT tested'
    },
    {
      value: 'nonFamily',
      label: 'Non-family'
    },
    {
      value: 'websearch',
      label: 'Web Search'
    },
    {
      value: 'twitter',
      label: 'Twitter'
    },
    {
      value: 'facebook',
      label: 'Facebook'
    },
    {
      value: 'instagram',
      label: 'Instagram'
    },
    {
      value: 'linkedin',
      label: 'LinkedIn'
    },
    {
      value: 'read',
      label: 'Read about in Publication'
    },
    {
      value: 'ad',
      label: 'Saw an ad (please specify where below)'
    },
    {
      value: 'speech',
      label: 'Heard in a speech'
    },
    {
      value: 'other',
      label: 'Other'
    }
  ]
}

export const defaultToggles = [
  {
    label: 'Hide',
    value: 'hide'
  },
  {
    label: 'Show',
    value: 'show'
  }
]
export const defaultReportFields = [
  {
    label: 'Appointment',
    id: 'appointment',
    options: [
      {
        id: 'FOLDNO',
        label: 'Folder Number'
      },
      {
        id: 'LAB',
        label: 'Lab Number'
      },
      {
        id: 'EXCODE',
        label: 'Examinee code'
      },
      {
        id: 'TDATE1',
        label: 'Test Date 1'
      },
      {
        id: 'TDATE2',
        label: 'Test Date 2'
      },
      {
        id: 'TDATE3',
        label: 'Test Date 3'
      }
    ]
  },
  {
    label: 'Client',
    id: 'client',
    options: [
      {
        id: 'FNAME',
        label: 'First Name'
      },
      {
        id: 'MNAME',
        label: 'Middle Name'
      },
      {
        id: 'LNAME',
        label: 'Last Name'
      },
      {
        id: 'ADDR',
        label: 'Address 1'
      },
      {
        id: 'ADDR2',
        label: 'Address 2'
      },
      {
        id: 'CITY',
        label: 'City'
      },
      {
        id: 'STATE',
        label: 'State'
      },
      {
        id: 'ZIP',
        label: 'ZIP'
      },
      {
        id: 'COUNTRY',
        label: 'Country'
      },
      {
        id: 'PHONE',
        label: 'Phone'
      },
      {
        id: 'BDATE',
        label: 'Birth date'
      },
      {
        id: 'SEX',
        label: 'Sex'
      },
      {
        id: 'AGE1',
        label: 'Appt 1 Age'
      },
      {
        id: 'AGE2',
        label: 'Appt 2 Age'
      },
      {
        id: 'AGE3',
        label: 'Appt 3 Age'
      },
      {
        id: 'REFMEMO',
        label: 'Referred by'
      }
    ]
  },
  {
    label: 'Client Education',
    id: 'client-edu',
    options: [
      {
        id: 'EDUC',
        label: 'Years of education'
      },
      {
        id: 'INSCHOOL',
        label: 'Currently in school or graduated, etc.'
      },
      {
        id: 'SCHOOL',
        label: 'Last year completed in school'
      },
      {
        id: 'MAJOR1',
        label: 'College Major'
      },
      {
        id: 'MAJOR2',
        label: 'College Major 2'
      }
    ]
  },
  {
    label: 'Client Family',
    id: 'client-fam',
    options: [
      {
        id: 'FATHEDUC',
        label: 'Years of Fathers education'
      },
      {
        id: 'MOTHEDUC',
        label: 'Years of Mothers education'
      }
    ]
  },
  {
    label: 'Client Employment',
    id: 'client-job',
    options: [
      {
        id: 'OCCUP',
        label: 'Occupation'
      },
      {
        id: 'JOBYRS',
        label: 'Number of years in current job'
      },
      {
        id: 'SATISFAC',
        label: '1-5 Satisfaction rating of job'
      }
    ]
  }
]
