import React from 'react'
import { navigate } from 'gatsby'

import Final from '../final'
import ProgressBar from '../../progressBar/info'

import { internal } from '../../../lib/routes'

const ClientInfoFinalPage = ({id}) => (
  <>
    <ProgressBar id={id} currentStep="7"></ProgressBar>
    <Final id={id} onSuccess={() => navigate(`/client-info/${id}/complete`)} />
  </>
);

export default ClientInfoFinalPage
