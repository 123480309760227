import React, { useContext } from 'react'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'

import { internal } from '../../lib/routes'
import ClientAPIContext from '../../services/client'

import InfoIndex from './pages'
import ClientInfoCompletePage from './pages/complete'
import ClientInfoEducationPage from './pages/education'
import ClientInfoEmploymentPage from './pages/employment'
import ClientInfoFamilyPage from './pages/family'
import ClientInfoFinalPage from './pages/final'
import ClientInfoInterestsPage from './pages/interests'
import ClientInfoPaymentPage from './pages/payment'
import ClientInfoPersonalPage from './pages/personal'
import LoadingIndicator from '../LoadingIndicator'

const Info = () => {
  const clientAPI = useContext(ClientAPIContext)
  const { id, isPending, isResolved } = clientAPI

  return (
    <>
      {isPending && <LoadingIndicator />}
      {isResolved && (
        <Router>
          <ClientInfoCompletePage id={id} path={internal(id).info.complete} />
          <ClientInfoEducationPage id={id} path={internal(id).info.education} />
          <ClientInfoEmploymentPage id={id} path={internal(id).info.employment} />
          <ClientInfoFamilyPage id={id} path={internal(id).info.family} />
          <ClientInfoFinalPage id={id} path={internal(id).info.final} />
          <ClientInfoInterestsPage id={id} path={internal(id).info.interests} />
          <ClientInfoPersonalPage id={id} path={internal(id).info.personal} />
          <ClientInfoPaymentPage id={id} path={`${internal(id).info.payment}/*orderId`} />
          <InfoIndex
            default
            id={id}
            next={() => navigate(`/${internal(id).info.personal}`)}
            payment={() => navigate(`${internal(id).info.payment}/`)}
          />
        </Router>
      )}
    </>
  )
}

export default Info
