import React from 'react'
import Button from '../../button'
import Icon from '../../icon'
import ProgressBar from '../../progressBar/info'
import ClientOrderDetail from '../../order/ClientOrderDetail'
import OrderDefault from '../../order/OrderDefault'
import Orders from '../../client/pages/Orders'
import { internal } from '../../../lib/routes'
import { navigate } from 'gatsby'

const ClientInfoPaymentPage = ({ id, orderId }) => {
  return (
    <>
              <ProgressBar id={id} currentStep="6" />
              <Orders id={id} path={`${internal(id).info.payment}`} allowEdit={false}>
                {/* Only call the first time to set default order */}
                {orderId === '' && <OrderDefault path="/" id={id} />}
                {/* Display orders for payment, do not allow edits */}
                <ClientOrderDetail clientId={id} orderId={orderId} allowEdit={false} />
                {/* Allow user to navigate to final screen */}
                <div className="card--inner card--divider layout-add-submit">
                  <div className="button-group">
                    <Button theme="primary" type="submit" onClick={() => navigate(`/client-info/${id}/final`)}>
                      <span className="button__label">Continue</span>
                      <Icon icon="forward" theme="primary" />
                    </Button>
                  </div>
                </div>
              </Orders>
              </>
  )
}

export default ClientInfoPaymentPage
